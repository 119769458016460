<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">对账列表</span>
            <!-- <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" @click="zqAdd">账期添加</el-button>
            </div> -->
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-company-under-select  label="商户" v-model="pageParam.params.id" placeholder="请选择（可输入搜索）"></le-company-under-select>
            </le-search-form>
            <le-pagview ref="financeDZ" @setData="setTableData" :pageParam="pageParam" :pagerCount="5" :isFixed="true" :tableRef="$refs.financeDZList">
                <el-table ref="financeDZList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="companyName" label="商户ID" >
                        <template slot-scope="{ row }">
                            <span>{{ row.id?row.id:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="paymentName" label="商户名称" >
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName?row.companyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomePrice" label="宝付钱包对账" >
                        <template slot-scope="{ row }">
                            <span class="font-point a-c-blue" @click="handleToDL(row,2)" v-if="row.wallet && row.wallet.indexOf(2) != -1">宝付钱包对账</span>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomePrice" label="微信钱包对账" >
                        <template slot-scope="{ row }">
                            <span class="font-point a-c-blue" @click="handleToDL(row,1)" v-if="row.wallet && row.wallet.indexOf(1) != -1">微信钱包对账</span>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="startDate" label="玖电钱包对账" >
                        <template slot-scope="{ row }">
                            <span class="font-point a-c-blue" @click="handleToJD(row)">查看</span>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.getCompanyBillStat,
                    method: "post",
                    params: {
                        id: '',
                    },
                    freshCtrl: 1,
                },
            }
        },
        created () {

        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    id: '',
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['financeDZ'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            handleToJD (datas) {
                this.$router.push({
                    path: '/finance/finance-reconciliation-jd',
                    query: {
                        companyId: datas?datas.id:'',
                        type: 0
                    }
                })
            },
            handleToDL (datas, type) {
                this.$router.push({
                    path: '/finance/finance-reconciliation-dl',
                    query: {
                        companyId: datas?datas.id:'',
                        type: type
                    }
                })
            },
            exportfile () {
                // this.$exfile({
                //     code: 3,
                //     fileName: this.pageParam.params.queryStartDate + ' 至 ' + this.pageParam.params.queryEndDate + ' 站点经营分析',
                //     startTime: this.pageParam.params.queryStartDate,
                //     endTime: this.pageParam.params.queryEndDate,
                //     params: this.pageParam.params
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    /deep/ .companySelect{
        .a-ml-20{
            margin-left: 10px !important;
        }
    }
</style>